define("trunk/templates/components/canny-changelog-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x56QWANr",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"data-canny-changelog\",\"\"],[12,\"data-intercom-target\",\"Changelog nav\"],[12,\"class\",[29,[[23,0,[\"styleNamespace\"]]]]],[13,1],[12,\"type\",\"button\"],[3,\"did-insert\",[[23,0,[\"initialize\"]]]],[3,\"on\",[\"click\",[23,0,[\"trackClick\"]]]],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/canny-changelog-trigger.hbs"
    }
  });

  _exports.default = _default;
});