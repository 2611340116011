define("trunk/models/password", ["exports", "ember-data", "trunk/mixins/models/changeable"], function (_exports, _emberData, _changeable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, {
    email: _emberData.default.attr('string'),
    value: _emberData.default.attr('string')
  });

  _exports.default = _default;
});