define("trunk/mixins/routes/paginatable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      }
    },
    resetController: function resetController(controller, isExiting, transition) {
      this._super(controller, isExiting, transition);

      if (isExiting) {
        // otherwise current page will be cached
        controller.resetPage();
      }
    }
  });

  _exports.default = _default;
});