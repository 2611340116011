define("trunk/templates/components/loaded-records/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uGypwtUI",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@isVisible\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[5,\"empty-placeholder\",[[12,\"class\",\"py-20 m-auto text-lg\"],[13,1]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,2],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/loaded-records/empty.hbs"
    }
  });

  _exports.default = _default;
});