define("trunk/templates/components/billing/trial-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ynpBKS3y",
    "block": "{\"symbols\":[],\"statements\":[[5,\"inline-alert\",[],[[\"@type\"],[\"info\"]],{\"statements\":[[0,\"\\n  There's still \"],[1,[23,0,[\"subscription\",\"trialRemainingDurationHumanized\"]],false],[0,\" remaining in your free trial. Don't worry, you won't be charged until after the trial ends!\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/billing/trial-alert.hbs"
    }
  });

  _exports.default = _default;
});