define("trunk/templates/components/variant-item-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sy3xNvVa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"inline-block w-8\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"far fa-level-up-alt text-body-secondary relative\"],[10,\"data-fa-transform\",\"rotate-90 right-7 up-1 grow-3\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/variant-item-icon.hbs"
    }
  });

  _exports.default = _default;
});