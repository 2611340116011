define("trunk/templates/components/external-service-type-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HFbWNd60",
    "block": "{\"symbols\":[\"externalServiceType\",\"@onClose\"],\"statements\":[[5,\"simple-modal\",[],[[\"@onClose\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center -mt-4\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"externalServiceTypes\"]]],null,{\"statements\":[[0,\"      \"],[5,\"simple-button\",[[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"selectType\"]],[23,1,[]]],null]]]],[[\"@design\"],[\"external-service-type\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"inline-svg\",[[28,\"concat\",[\"icon-\",[28,\"dasherize\",[[23,1,[]]],null],\"-white.svg\"],null]],[[\"class\"],[[28,\"dasherize\",[[23,1,[]]],null]]]],false],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"h-6\"],[8],[1,[28,\"humanize-external-service-type\",[[23,1,[]]],null],false],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/external-service-type-modal.hbs"
    }
  });

  _exports.default = _default;
});