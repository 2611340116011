define("trunk/initializers/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // Inject current-user service into all controllers as currentUser
  function initialize(application) {
    application.inject('controller', 'currentUser', 'service:current-user');
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});