define("trunk/templates/passwords/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xYTHJBdD",
    "block": "{\"symbols\":[],\"statements\":[[5,\"gateway-layout\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-2/5 m-auto\"],[8],[0,\"\\n    \"],[5,\"simple-form\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"page-heading\"],[8],[0,\"Reset your password\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[7,\"label\",true],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Email\"],[9],[0,\"\\n          \"],[5,\"text-input\",[],[[\"@changeset\",\"@key\",\"@class\",\"@isAutofocused\"],[[23,0,[\"passwordChangeset\"]],\"email\",\"w-full\",true]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"simple-button\",[],[[\"@type\",\"@size\",\"@task\"],[\"submit\",\"submit\",[23,0,[\"createPasswordTask\"]]]],{\"statements\":[[0,\"\\n        Send instructions\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/passwords/new.hbs"
    }
  });

  _exports.default = _default;
});