define("trunk/templates/components/table-row-ellipsis-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E//mBqXw",
    "block": "{\"symbols\":[\"tableRowDropdown\",\"content\",\"@isLoading\",\"&default\"],\"statements\":[[5,\"table-row-dropdown\",[],[[\"@isLoading\",\"@type\"],[[23,3,[]],\"menu\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"Trigger\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-block cursor-pointer w-10 text-center text-body-secondary hover:text-primary\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"far fa-ellipsis-v\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"Content\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n      \"],[14,4,[[23,2,[]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/table-row-ellipsis-dropdown.hbs"
    }
  });

  _exports.default = _default;
});