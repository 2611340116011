define("trunk/mixins/routes/sessionable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    attemptTransitionAfterAuthentication: function attemptTransitionAfterAuthentication() {
      var routeAfterAuthentication = this.session.routeAfterAuthentication;

      if (!routeAfterAuthentication) {
        return false;
      } // Make sure it's reset so that we don't redirect in a loop


      this.session.set('routeAfterAuthentication', null);
      return this.transitionTo(routeAfterAuthentication);
    }
  });

  _exports.default = _default;
});