define("trunk/utils/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseBaseErrorFromException = parseBaseErrorFromException;

  function parseBaseErrorFromException(exception) {
    var errorSource = exception.errors.find(function (e) {
      return e.source.pointer == '/data/attributes/base';
    });

    if (errorSource) {
      return errorSource.detail;
    }

    return null;
  }
});