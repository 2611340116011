define("trunk/models/payment-method", ["exports", "ember-data", "trunk/mixins/models/external-serviceable"], function (_exports, _emberData, _externalServiceable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_externalServiceable.default, {
    organization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    kind: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    isDefault: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});