define("trunk/templates/components/simple-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aHpy67am",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"button\"],[[28,\"component\",[\"simple-button\"],[[\"task\"],[[23,0,[\"task\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/simple-form.hbs"
    }
  });

  _exports.default = _default;
});