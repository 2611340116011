define("trunk/templates/components/simple-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oKMP2Ock",
    "block": "{\"symbols\":[\"dropdown\",\"@type\",\"@isLoading\",\"&default\"],\"statements\":[[5,\"basic-dropdown\",[],[[\"@calculatePosition\"],[[23,0,[\"calculatePosition\"]]]],{\"statements\":[[0,\"\\n  \"],[14,4,[[28,\"hash\",null,[[\"Trigger\",\"Content\"],[[28,\"component\",[\"simple-dropdown/trigger\"],[[\"dropdown\",\"isLoading\"],[[23,1,[]],[23,3,[]]]]],[28,\"component\",[\"simple-dropdown/content\"],[[\"dropdown\",\"type\"],[[23,1,[]],[23,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/simple-dropdown.hbs"
    }
  });

  _exports.default = _default;
});