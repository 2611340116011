define("trunk/templates/components/simple-dropdown/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qbb7sh5S",
    "block": "{\"symbols\":[\"@dropdown\",\"&default\"],\"statements\":[[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[[23,0,[\"styleNamespace\"]],\" \",[28,\"if\",[[23,0,[\"isMenu\"]],[28,\"concat\",[[23,0,[\"styleNamespace\"]],\"--menu\"],null]],null]]]],[3,\"on\",[\"click\",[23,0,[\"handleClick\"]]]],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"dropdown\",\"styleNamespace\"],[[23,1,[]],[23,0,[\"styleNamespace\"]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/simple-dropdown/content.hbs"
    }
  });

  _exports.default = _default;
});