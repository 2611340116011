define("trunk/templates/components/gateway-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "psO9YSNX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"loading-spinner-modal\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"m-auto\"],[10,\"style\",\"width: 750px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"m-auto pt-20 pb-16\"],[10,\"style\",\"width: 126px\"],[8],[0,\"\\n    \"],[5,\"external-link\",[],[[\"@href\",\"@event\"],[\"https://www.trunkinventory.com\",\"Gateway Logo Clicked\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"inline-svg\",[\"trunk-logo.svg\"],[[\"class\"],[\"w-full h-full\"]]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/gateway-layout.hbs"
    }
  });

  _exports.default = _default;
});