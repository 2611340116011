define("trunk/templates/components/product-variant-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0SkBqofp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"loaded-content\",[],[[\"@task\"],[[23,0,[\"loadTask\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"simple-form\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[7,\"label\",true],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Name\"],[9],[0,\"\\n          \"],[5,\"text-input\",[],[[\"@changeset\",\"@key\",\"@class\",\"@isAutofocused\"],[[23,0,[\"productVariantChangeset\"]],\"name\",\"w-112\",true]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"components-editor\",[],[[\"@inputClass\",\"@parent\",\"@componentChangesets\",\"@onComponentsChange\"],[\"w-128\",[23,0,[\"productVariant\"]],[23,0,[\"componentChangesets\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"componentChangesets\"]]],null]],null]]]],[0,\"\\n    \"],[5,\"simple-button\",[],[[\"@task\",\"@type\",\"@size\"],[[23,0,[\"saveTask\"]],\"submit\",\"submit\"]],{\"statements\":[[0,\"\\n      Save\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/product-variant-form.hbs"
    }
  });

  _exports.default = _default;
});