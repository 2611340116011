define("trunk/models/referral", ["exports", "ember-data", "trunk/helpers/format-cents"], function (_exports, _emberData, _formatCents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    referred: _emberData.default.belongsTo('organization', {
      async: false
    }),
    commissionCents: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    commissionFormatted: Ember.computed('commissionCents', 'currency', function () {
      return (0, _formatCents.formatCents)([this.commissionCents, this.currency], {
        style: 'simple'
      });
    }),
    statusBadgeType: Ember.computed('status', function () {
      var statuses = {
        earned: 'success',
        used: 'success'
      };
      return statuses[this.status];
    })
  });

  _exports.default = _default;
});