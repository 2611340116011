define("trunk/templates/components/table-row-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hlGzxGzy",
    "block": "{\"symbols\":[\"@onClose\",\"@heading\",\"&default\"],\"statements\":[[7,\"tr\",false],[12,\"class\",\"table-row-modal-component full-table__row--unselectable\"],[3,\"did-mutate\",[[23,0,[\"calculateDimensions\"]]]],[8],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"table-row-modal-component__container\"],[10,\"colspan\",\"9999\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"absolute\"],[10,\"style\",\"top: 10px; right: 14px\"],[8],[0,\"\\n      \"],[5,\"icon-control\",[[12,\"class\",\"z-50\"],[3,\"on\",[\"click\",[23,1,[]]]]],[[\"@type\"],[\"close\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-row-modal-component__heading text-white\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-row-modal-component__content\"],[8],[0,\"\\n      \"],[14,3],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/table-row-modal.hbs"
    }
  });

  _exports.default = _default;
});