define("trunk/templates/components/canny-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l/qKlLUU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"script\",true],[8],[0,\"\\n  !function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type=\\\"text/javascript\\\",e.async=!0,e.src=\\\"https://canny.io/sdk.js\\\",f.parentNode.insertBefore(e,f)}}if(\\\"function\\\"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,\\\"complete\\\"===d.readyState?l():w.attachEvent?w.attachEvent(\\\"onload\\\",l):w.addEventListener(\\\"load\\\",l,!1)}}(window,document,\\\"canny-jssdk\\\",\\\"script\\\");\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/canny-integration.hbs"
    }
  });

  _exports.default = _default;
});