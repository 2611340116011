define("trunk/templates/components/fetchable-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UhqcFBex",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"isFetching\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex items-center justify-center\"],[11,\"style\",[23,0,[\"style\"]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-sync-alt inline-block\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"styleNamespace\"]],\"__image\"]]],[11,\"alt\",[23,0,[\"alt\"]]],[11,\"style\",[23,0,[\"imageStyle\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/fetchable-image.hbs"
    }
  });

  _exports.default = _default;
});