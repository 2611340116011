define("trunk/styles/tailwind", [], function () {
  "use strict";

  /* global module */
  module.exports = {
    important: true,
    // Determine how to purge unused tailwind classes. This is only run when NODE_ENV is production
    purge: {
      content: [// Add extra paths here for anything which include tailwind classes
      './app/index.html', './app/components/**/*.js', './app/templates/**/*.hbs'],
      css: ['./app/styles/**/*.scss']
    },
    theme: {
      colors: {
        'transparent': 'transparent',
        'body': '#666',
        'body-secondary': '#999',
        'black': '#333',
        'danger': '#d24d57',
        'light': '#eee',
        'light-hover': '#ddd',
        'muted': '#ccc',
        'soft': '#b5b5b5',
        'primary': '#44c0ff',
        'primary-hover': '#2980b9',
        'primary-subtle': '#f6fcff',
        'success': '#10998d',
        'warning': '#f39c12',
        'white': '#fff'
      },
      fontSize: {
        'xs': '0.75rem',
        'sm': '0.875rem',
        'md': '0.9375rem',
        'base': '1rem',
        'lg': '1.125rem',
        'xl': '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4rem'
      },
      spacing: {
        'px': '1px',
        '0': '0',
        '1': '0.25rem',
        '2': '0.5rem',
        '3': '0.75rem',
        '4': '1rem',
        '5': '1.25rem',
        '6': '1.5rem',
        '8': '2rem',
        '10': '2.5rem',
        '12': '3rem',
        '14': '3.5rem',
        '16': '4rem',
        '20': '5rem',
        '24': '6rem',
        '32': '8rem',
        '40': '10rem',
        '48': '12rem',
        '56': '14rem',
        '64': '16rem',
        '80': '18rem',
        '96': '24rem',
        '112': '36rem',
        '128': '52rem'
      },
      extend: {
        fontSize: {
          'compact': '.9375rem'
        },
        inset: {
          '4': '1rem'
        }
      }
    },
    variants: {},
    plugins: []
  };
});