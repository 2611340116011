define("trunk/mixins/controllers/queryable", ["exports", "trunk/utils/routing"], function (_exports, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['query'],
    query: null,
    resetQuery: function resetQuery() {
      this.set('query', null);
    },
    actions: {
      handleQueryChange: function handleQueryChange(query) {
        (0, _routing.transitionToOrRefreshRoute)(this, {
          queryParams: {
            query: query,
            page: 1
          }
        });
      }
    }
  });

  _exports.default = _default;
});