define("trunk/models/installation", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    externalServiceType: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    originUrl: _emberData.default.attr('string'),
    organizationName: _emberData.default.attr('string'),
    userName: _emberData.default.attr('string'),
    userEmail: _emberData.default.attr('string'),
    associate: (0, _emberApiActions.memberAction)({
      path: 'associate',
      type: 'patch'
    })
  });

  _exports.default = _default;
});