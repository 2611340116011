define("trunk/models/linked-sales-channel-aggregation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    aggregatable: _emberData.default.belongsTo('aggregatable', {
      polymorphic: true,
      inverse: 'salesChannelAggregations',
      async: false
    }),
    salesChannel: _emberData.default.belongsTo('sales-channel', {
      async: false
    }),
    // Supposed to be polymorphic belongsTo relationships
    product: _emberData.default.belongsTo('product', {
      async: false
    }),
    productVariant: _emberData.default.belongsTo('product', {
      async: false
    }),
    linkedCount: _emberData.default.attr('number'),
    availableQuantity: _emberData.default.attr('number'),
    isVariableAvailableQuantity: _emberData.default.attr('boolean'),
    isSoldOut: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});