define("trunk/templates/passwords/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r7U0zA9I",
    "block": "{\"symbols\":[\"loaded\",\"result\"],\"statements\":[[5,\"gateway-layout\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-2/5 m-auto\"],[8],[0,\"\\n    \"],[5,\"loaded-record\",[],[[\"@task\"],[[23,0,[\"model\",\"findPasswordTask\"]]]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"missing\"]],[[12,\"class\",\"text-center\"]],[[],[]],{\"statements\":[[0,\"\\n        Your password reset expired\\n        \"],[7,\"br\",true],[8],[9],[5,\"link-to\",[],[[\"@route\"],[\"passwords.new\"]],{\"statements\":[[0,\"Try again?\"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,1,[\"exists\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"password/form\",[],[[\"@password\"],[[23,2,[\"record\"]]]]],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/passwords/show.hbs"
    }
  });

  _exports.default = _default;
});