define("trunk/templates/components/product-variant-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MJB9qGIu",
    "block": "{\"symbols\":[\"row\",\"@productVariant\"],\"statements\":[[4,\"with\",[[28,\"component\",[\"product-variant-item/row\"],[[\"productVariant\"],[[23,2,[]]]]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/product-variant-item.hbs"
    }
  });

  _exports.default = _default;
});