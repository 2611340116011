define("trunk/helpers/current-year", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentYear = currentYear;
  _exports.default = void 0;

  function currentYear() {
    var date = new Date();
    return Ember.String.htmlSafe((0, _moment.default)(date).format('YYYY'));
  }

  var _default = Ember.Helper.helper(currentYear);

  _exports.default = _default;
});