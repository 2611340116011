define("trunk/templates/components/pagination-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "abaFxGEe",
    "block": "{\"symbols\":[\"@page\",\"@pagesCount\"],\"statements\":[[4,\"if\",[[23,0,[\"isVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",[29,[\"inline-block text-center w-10 text-xl \",[28,\"if\",[[23,0,[\"hasPreviousPage\"]],\"text-primary hover:text-primary-hover cursor-pointer\",\"text-transparent cursor-default\"],null]]]],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"transitionToPreviousPage\"]]]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-left\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-block p-0\"],[8],[1,[23,1,[]],false],[0,\" / \"],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",[29,[\"inline-block text-center w-10 text-xl \",[28,\"if\",[[23,0,[\"hasNextPage\"]],\"text-primary hover:text-primary-hover cursor-pointer\",\"text-transparent cursor-default\"],null]]]],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"transitionToNextPage\"]]]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-right\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/pagination-nav.hbs"
    }
  });

  _exports.default = _default;
});