define("trunk/templates/components/simple-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fazUfPSo",
    "block": "{\"symbols\":[\"@heading\",\"&attrs\",\"@onClose\",\"&default\"],\"statements\":[[7,\"div\",false],[13,2],[8],[0,\"\\n  \"],[5,\"modal-dialog\",[],[[\"@containerClassNames\",\"@onClose\",\"@tetherTarget\",\"@targetAttachment\",\"@targetOffset\"],[[23,0,[\"containerClassNames\"]],[23,3,[]],\"body\",\"top center\",\"35% 0\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"heading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"simple-modal-component__heading text-white\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"absolute\"],[10,\"style\",\"top: 7px; right: 14px\"],[8],[0,\"\\n      \"],[5,\"icon-control\",[[3,\"on\",[\"click\",[23,3,[]]]]],[[\"@type\"],[\"close\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[14,4],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/simple-modal.hbs"
    }
  });

  _exports.default = _default;
});