define("trunk/templates/public", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DWmIu65n",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\"],[\"new-user\"]],{\"statements\":[[0,\"Sign up\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"link-to\",[],[[\"@route\"],[\"new-session\"]],{\"statements\":[[0,\"Login\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/public.hbs"
    }
  });

  _exports.default = _default;
});