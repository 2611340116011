define("trunk/templates/components/inline-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8NSz2ZJk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"text-center flex-shrink-0 text-2xl w-16 \",[23,0,[\"iconClassName\"]]]]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fas fa-\",[23,0,[\"icon\"]]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex-grow text-body-secondary\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/inline-alert.hbs"
    }
  });

  _exports.default = _default;
});