define("trunk/templates/components/collapsible-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uOMtdIK8",
    "block": "{\"symbols\":[\"&attrs\",\"@isCollapsed\",\"@badge\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"flex items-center inline-block select-none cursor-pointer hover:text-primary-hover\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"w-6 \",[28,\"if\",[[23,2,[]],\"text-primary\",\"text-body\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fas fa-caret-right\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fas fa-caret-down\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[1,[23,3,[]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/collapsible-control.hbs"
    }
  });

  _exports.default = _default;
});