define("trunk/templates/components/external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I4h8CmBw",
    "block": "{\"symbols\":[\"@href\",\"&attrs\",\"&default\"],\"statements\":[[7,\"a\",false],[12,\"href\",[23,1,[]]],[13,2],[3,\"on\",[\"click\",[23,0,[\"open\"]]]],[8],[14,3],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/external-link.hbs"
    }
  });

  _exports.default = _default;
});