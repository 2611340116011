define("trunk/templates/sales-channel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KHBkgCxJ",
    "block": "{\"symbols\":[\"loaded\",\"result\"],\"statements\":[[7,\"h1\",true],[10,\"class\",\"page-heading\"],[8],[0,\"Sales channel\"],[9],[0,\"\\n\"],[5,\"loaded-record\",[],[[\"@task\"],[[23,0,[\"model\",\"findSalesChannelTask\"]]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"missing\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"empty-placeholder\",[],[[],[]],{\"statements\":[[0,\"That doesn't exist!\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"exists\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"sales-channel-form\",[],[[\"@salesChannel\"],[[23,2,[\"record\"]]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/sales-channel.hbs"
    }
  });

  _exports.default = _default;
});