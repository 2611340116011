define("trunk/models/product-variant", ["exports", "ember-data", "ember-api-actions", "trunk/mixins/models/changeable", "ember-data-storefront/mixins/loadable-model", "trunk/mixins/models/variantable", "trunk/mixins/models/sales-channel-aggregatable"], function (_exports, _emberData, _emberApiActions, _changeable, _loadableModel, _variantable, _salesChannelAggregatable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, _loadableModel.default, _salesChannelAggregatable.default, _variantable.default, {
    defaultInventory: _emberData.default.belongsTo('inventory', {
      async: false
    }),
    product: _emberData.default.belongsTo('product', {
      async: false
    }),
    linkedListingVariants: _emberData.default.hasMany('listing-variant', {
      async: false
    }),
    components: _emberData.default.hasMany('component', {
      inverse: 'parent',
      async: false
    }),
    componentizations: _emberData.default.hasMany('component', {
      inverse: 'productVariant',
      async: false
    }),
    name: _emberData.default.attr('string'),
    sku: _emberData.default.attr('string'),
    properties: _emberData.default.attr(),
    isLinked: _emberData.default.attr('boolean'),
    availableQuantity: _emberData.default.attr('number'),
    onHandQuantity: _emberData.default.attr('number'),
    componentsCount: _emberData.default.attr('number'),
    isAvailableQuantityThresholdAlerted: _emberData.default.attr('boolean'),
    fullName: Ember.computed('product.name', 'name', function () {
      if (Ember.isBlank(this.name) || !this.get('product.hasVariants')) {
        return "".concat(this.get('product.name'));
      } else {
        return "".concat(this.get('product.name'), ", ").concat(this.name);
      }
    }),
    putComponents: (0, _emberApiActions.memberAction)({
      path: 'relationships/components',
      type: 'put'
    }),
    hasComponents: Ember.computed('componentsCount', function () {
      return this.componentsCount > 0;
    }),
    updateComponents: function updateComponents(components) {
      var _this = this;

      var componentsData = components.map(function (component) {
        return {
          quantity: component.get('quantity'),
          'product-variant-id': component.get('productVariant.id')
        };
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.putComponents({
          data: componentsData
        }).then(function (response) {
          // Will update current record with new data
          _this.store.pushPayload('product-variant', response);

          resolve(_this);
        });
      });
    },
    newComponentChangesetsWithValidations: function newComponentChangesetsWithValidations(validations) {
      return this.components.map(function (component) {
        return component.newChangesetWithValidations(validations);
      });
    }
  });

  _exports.default = _default;
});