define("trunk/modifiers/keyboard-shortcut", ["exports", "ember-keyboard/deprecated/modifiers/keyboard-shortcut"], function (_exports, _keyboardShortcut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _keyboardShortcut.default;
    }
  });
});