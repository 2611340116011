define("trunk/templates/components/loaded-record/missing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sUvjk4jk",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@isVisible\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[13,1],[8],[14,2],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/loaded-record/missing.hbs"
    }
  });

  _exports.default = _default;
});