define("trunk/templates/sales-channels/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5zjaXSQc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"page-heading\"],[8],[0,\"Create sales channel\"],[9],[0,\"\\n\"],[5,\"sales-channel-form\",[],[[\"@salesChannel\"],[[23,0,[\"model\",\"salesChannel\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/sales-channels/new.hbs"
    }
  });

  _exports.default = _default;
});