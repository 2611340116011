define("trunk/templates/components/select-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sIxWcneH",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[5,\"power-select\",[],[[\"@searchEnabled\",\"@selected\",\"@options\",\"@onchange\"],[[23,0,[\"searchEnabled\"]],[23,0,[\"selectedOption\"]],[23,0,[\"opts\"]],[28,\"action\",[[23,0,[]],\"selectOption\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/select-input.hbs"
    }
  });

  _exports.default = _default;
});