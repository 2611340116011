define("trunk/models/listing", ["exports", "ember-data", "ember-api-actions", "trunk/mixins/models/monetizable", "trunk/mixins/models/variant-manyable"], function (_exports, _emberData, _emberApiActions, _monetizable, _variantManyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_monetizable.default, _variantManyable.default, {
    salesChannel: _emberData.default.belongsTo('sales-channel', {
      async: false
    }),
    variants: _emberData.default.hasMany('listing-variant', {
      async: false
    }),
    image: _emberData.default.belongsTo('listing-image', {
      async: false
    }),
    images: _emberData.default.hasMany('listing-image', {
      async: false
    }),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    sku: _emberData.default.attr('string'),
    skuPatternPrefixCount: _emberData.default.attr('string'),
    skuPatternSuffixCount: _emberData.default.attr('string'),
    availableQuantity: _emberData.default.attr('number'),
    price: _emberData.default.attr('string'),
    isVariablePrice: _emberData.default.attr('boolean'),
    isLinked: _emberData.default.attr('boolean'),
    isSoldOutOverridden: _emberData.default.attr('boolean'),
    externalServiceType: _emberData.default.attr('string'),
    externalServiceIdentifier: _emberData.default.attr('string'),
    externalServiceData: _emberData.default.attr(),
    externalServiceState: _emberData.default.attr(),
    triggerAutolink: (0, _emberApiActions.memberAction)({
      path: 'autolink',
      type: 'post'
    }),
    triggerRefresh: (0, _emberApiActions.memberAction)({
      path: 'refresh',
      type: 'post'
    }),
    variantsCount: Ember.computed('variants.[]', function () {
      // Use ds-references so we don't trigger request
      return this.hasMany('variants').ids().length;
    }),
    hasVariants: Ember.computed('variantsCount', function () {
      // There is always at least one variant
      return this.variantsCount > 1;
    }),
    isSimple: Ember.computed('variantsCount', function () {
      return this.variantsCount <= 1;
    })
  });

  _exports.default = _default;
});