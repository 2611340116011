define("trunk/mixins/components/modalable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      handleClose: function handleClose() {
        this.onClose();
      }
    },
    onClose: function onClose() {}
  });

  _exports.default = _default;
});