define("trunk/mixins/models/sales-channel-aggregatable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    salesChannelAggregations: _emberData.default.hasMany('linked-sales-channel-aggregation', {
      inverse: 'aggregatable',
      async: false
    })
  });

  _exports.default = _default;
});