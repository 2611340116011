define("trunk/components/simple-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    window: Ember.inject.service(),
    tagName: 'button',
    classNames: ['simple-button-component'],
    classNameBindings: ['isDisabled:simple-button-component--disabled', 'isSuccessful:simple-button-component--success', 'colorClassName', 'sizeClassName', 'designClassName', 'blockClassName'],
    attributeBindings: ['isDisabled:disabled', 'isSuccessful:disabled', 'type'],
    isBlock: false,
    type: 'button',
    size: null,
    design: null,
    color: 'primary',
    task: null,
    changeset: null,
    href: null,
    isLoading: false,
    // Requires changeset to be dirtied in order for button to be enabled
    isNewChangesetDirtyRequired: true,
    sizeClassName: Ember.computed('size', function () {
      var size = this.size;

      if (size != null) {
        return "simple-button-component--".concat(size, "-size");
      }

      return;
    }),
    designClassName: Ember.computed('design', function () {
      return "simple-button-component--".concat(this.design, "-design");
    }),
    colorClassName: Ember.computed('color', function () {
      return "simple-button-component--".concat(this.color, "-color");
    }),
    blockClassName: Ember.computed('isBlock', function () {
      if (this.isBlock) {
        return 'simple-button-component--block';
      }

      return;
    }),
    isDisabled: Ember.computed('isBusy', 'isNewChangesetDirtyRequired', 'changeset.{isDirty,isNew,isValid}', function () {
      // If it's busy we need to disable right away
      if (this.isBusy) return true;

      if (this.changeset) {
        if (!this.isNewChangesetDirtyRequired) return false; // Disable button if there are no changes

        return !this.changeset.isDirty;
      }

      return false;
    }),
    isSuccessful: Ember.computed('isBusy', function () {
      if (this.task && this.task.last) {
        var value = this.task.last.value; // Requires the task return status in its result in order to mark
        // as successful

        if (value && _typeof(value) === 'object' && 'status' in value) {
          return value.status == 'success';
        }
      }

      return false;
    }),
    isTaskRunning: Ember.computed.alias('task.isRunning'),
    isBusy: Ember.computed('isLoading', 'isTaskRunning', function () {
      return this.isLoading || this.isTaskRunning;
    }),
    click: function click() {
      if (!this.isDisabled) {
        if (this.task) {
          this.task.perform();
        } else if (this.href) {
          this.window.redirectTo(this.href);
        }

        this.onClick();
      }
    },
    onClick: function onClick() {}
  });

  _exports.default = _default;
});