define("trunk/templates/components/long-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Si3M0XZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"pre\",\"content\",\"post\"],[[28,\"component\",[\"long-content/pre\"],null],[28,\"component\",[\"long-content/content\"],null],[28,\"component\",[\"long-content/post\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/long-content.hbs"
    }
  });

  _exports.default = _default;
});