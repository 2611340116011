define("trunk/templates/components/table-row-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZdOvjVbm",
    "block": "{\"symbols\":[\"simpleDropdown\",\"@isLoading\",\"@type\",\"&default\"],\"statements\":[[5,\"simple-dropdown\",[],[[\"@isLoading\",\"@margin\",\"@position\",\"@type\"],[[23,2,[]],\"4\",\"below\",[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[14,4,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/table-row-dropdown.hbs"
    }
  });

  _exports.default = _default;
});