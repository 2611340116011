define("trunk/mixins/models/monetizable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    price: _emberData.default.attr('string'),
    priceCents: _emberData.default.attr('number'),
    priceCurrency: _emberData.default.attr('string')
  });

  _exports.default = _default;
});