define("trunk/mixins/routes/authenticated", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    authenticationRoute: 'new-session',
    currentUser: Ember.inject.service('current-user'),
    destroyUserSession: function destroyUserSession() {
      this.currentUser.destroySession();
    }
  });

  _exports.default = _default;
});