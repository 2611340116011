define("trunk/utils/routing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transitionToOrRefreshRoute = transitionToOrRefreshRoute;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  // Gracefully transitions to route if we're not there yet otherwise does a refresh so everything transitions as expected
  function transitionToOrRefreshRoute(container, routeName, options) {
    // Can just pass in options and no route name as well
    if (_typeof(routeName) === 'object') {
      options = routeName;
      routeName = null;
    }

    var owner = Ember.getOwner(container);
    var router = owner.lookup('service:router');
    var currentRouteInfo = router.currentRoute;
    var desiredQueryParams = (options || {}).queryParams || {}; // We assume we need to transition by default

    var isRefresh = false; // If we're on the same route as to one we want to transition to

    if (currentRouteInfo.name == routeName || routeName == null) {
      var currentQueryParams = router.currentRoute.queryParams; // Assume we need to refresh cause we are on the same route

      isRefresh = true; // We need to transition instead if the query params are different. We only need to refresh if query params haven't
      // changed since a transition wouldn't do anything

      for (var _i = 0, _Object$entries = Object.entries(desiredQueryParams); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];

        if (currentQueryParams[key] != desiredQueryParams[key]) {
          isRefresh = false;
          break;
        }
      }
    }

    if (isRefresh) {
      var currentRoute = owner.lookup("route:".concat(currentRouteInfo.name));
      currentRoute.refresh();
    } else {
      if (routeName) {
        router.transitionTo(routeName, {
          queryParams: desiredQueryParams
        });
      } else {
        router.transitionTo({
          queryParams: desiredQueryParams
        });
      }
    }
  }
});