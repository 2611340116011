define("trunk/templates/settings/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4qrlUUuU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"page-heading\"],[8],[0,\"Organization\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[5,\"text-input\",[],[[\"@type\",\"@changeset\",\"@key\",\"@class\"],[\"text\",[23,0,[\"organizationChangeset\"]],\"name\",\"w-80\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"simple-button\",[],[[\"@type\",\"@size\",\"@changeset\",\"@task\"],[\"submit\",\"submit\",[23,0,[\"organizationChangeset\"]],[23,0,[\"saveTask\"]]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Data\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"secondary-link text-danger\"],[10,\"id\",\"intercom-delete-organization-trigger\"],[10,\"type\",\"button\"],[8],[0,\"Delete \"],[1,[23,0,[\"currentUser\",\"organization\",\"name\"]],false],[0,\"...\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/settings/organization.hbs"
    }
  });

  _exports.default = _default;
});