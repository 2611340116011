define("trunk/templates/components/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VDmSVF/F",
    "block": "{\"symbols\":[\"&default\",\"@class\",\"&attrs\",\"@labelClass\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"flex items-start \",[23,2,[]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mr-2 mt-1 flex\"],[8],[0,\"\\n    \"],[7,\"input\",false],[12,\"checked\",[23,0,[\"isChecked\"]]],[13,3],[12,\"type\",\"checkbox\"],[3,\"on\",[\"input\",[23,0,[\"handleChange\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-grow\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",[29,[\"cursor-pointer select-none \",[23,4,[]]]]],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"handleLabelClick\"]]]],[8],[1,[23,0,[\"labelHtml\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"text-body-secondary\"],[8],[14,1],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/checkbox-group.hbs"
    }
  });

  _exports.default = _default;
});