define("trunk/mixins/models/external-serviceable", ["exports", "ember-data", "trunk/helpers/humanize-external-service-type"], function (_exports, _emberData, _humanizeExternalServiceType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    externalServiceType: _emberData.default.attr('string'),
    externalServiceTypeId: _emberData.default.attr('number'),
    externalServiceData: _emberData.default.attr(),
    externalServiceTypePretty: Ember.computed('externalServiceType', function () {
      if (!this.externalServiceType) return;
      return (0, _humanizeExternalServiceType.humanizeExternalServiceType)(this.externalServiceType);
    })
  });

  _exports.default = _default;
});