define("trunk/templates/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WfjsEnh+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"loading-spinner-modal\"],[8],[9],[0,\"\\n\"],[5,\"nav-bar\",[],[[],[]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"footer-push\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"footer\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"legal.terms\"]],{\"statements\":[[0,\"Terms\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isAdminBarVisible\"]]],null,{\"statements\":[[0,\"  \"],[5,\"admin-bar\",[],[[\"@onClose\"],[[23,0,[\"handleCloseAdminBar\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[5,\"canny-integration\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/main.hbs"
    }
  });

  _exports.default = _default;
});