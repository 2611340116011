define("trunk/components/sales-channel-quantity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['soldOutClassName'],
    'data-test-sales-channel-quantity-component': Ember.computed.readOnly('salesChannel.id'),
    externalServiceType: Ember.computed.alias('salesChannel.externalServiceType'),
    salesChannel: null,
    aggregatable: null,
    quantity: null,
    isVariableQuantity: false,
    isSoldOut: false,
    soldOutClassName: Ember.computed('isSoldOut', function () {
      return this.isSoldOut ? 'sales-channel-quantity-component--sold-out' : null;
    }),
    isOutOfStock: Ember.computed('quantity', function () {
      return this.quantity <= 0;
    }),
    isLinkedCountVisible: Ember.computed('aggregatable', 'linkedCount', function () {
      if (Ember.isEmpty(this.aggregatable)) return false; // Don't show linked counts for products that have variants

      if (this.aggregatable.constructor.modelName == 'product' && this.aggregatable.variantsCount > 1) {
        return false;
      } // Don't show linked counts unless more than 1


      if (this.linkedCount <= 1) return false;
      return true;
    })
  });

  _exports.default = _default;
});