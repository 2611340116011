define("trunk/models/aggregatable", ["exports", "ember-data", "trunk/mixins/models/sales-channel-aggregatable"], function (_exports, _emberData, _salesChannelAggregatable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_salesChannelAggregatable.default, {});

  _exports.default = _default;
});