define("trunk/templates/components/loaded-records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "57iMsM/z",
    "block": "{\"symbols\":[\"&default\",\"@task\"],\"statements\":[[7,\"div\",false],[3,\"did-mutate\",[[23,0,[\"handleLoadingChange\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-spinner-modal\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[4,\"if\",[[23,2,[\"lastSuccessful\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"emptyUnfiltered\",\"emptyFiltered\",\"any\"],[[28,\"component\",[\"loaded-records/empty\"],[[\"isVisible\"],[[23,0,[\"areUnfilteredRecordsEmpty\"]]]]],[28,\"component\",[\"loaded-records/empty\"],[[\"isVisible\"],[[23,0,[\"areFilteredRecordsEmpty\"]]]]],[28,\"component\",[\"loaded-records/any\"],[[\"isVisible\",\"records\",\"pagesCount\"],[[23,0,[\"areRecordsAny\"]],[23,0,[\"records\"]],[23,0,[\"pagesCount\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/loaded-records.hbs"
    }
  });

  _exports.default = _default;
});