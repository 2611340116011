define("trunk/helpers/humanize-external-service-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanizeExternalServiceType = humanizeExternalServiceType;
  _exports.default = void 0;

  function humanizeExternalServiceType(type) {
    var humanized = {
      aims360: 'AIMS360',
      amazon_sp: 'Amazon',
      bandcamp: 'Bandcamp',
      bigcommerce: 'BigCommerce',
      ebay: 'eBay',
      etsy: 'Etsy',
      etsy_v2: 'Etsy',
      facebook: 'Facebook',
      faire: 'Faire',
      google_shopping: 'Google Shopping',
      quickbooks_online: 'QuickBooks Online',
      shopify: 'Shopify',
      square: 'Square',
      squarespace: 'Squarespace',
      squarespace_legacy: 'Squarespace',
      tiktok: 'TikTok',
      tundra: 'Tundra',
      walmart: 'Walmart',
      wix: 'Wix',
      woocommerce: 'WooCommerce',
      xero: 'Xero'
    };
    return humanized[type] || type;
  }

  var _default = Ember.Helper.helper(humanizeExternalServiceType);

  _exports.default = _default;
});