define("trunk/components/linked-sales-channel-quantities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex items-center'],
    // Sort by sales channel name alphabetically to make it deterministic
    aggregationsBySalesChannelName: Ember.computed.sort('aggregations', function (a, b) {
      // Lowercase the names so that sorting can work with different cased names (e.g. eBay will actually be sorted after
      // Shopify because 'e' is lowercase)
      var aName = a.salesChannel.name.toLowerCase();
      var bName = b.salesChannel.name.toLowerCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    })
  });

  _exports.default = _default;
});