define("trunk/templates/components/table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KjxZ+mrF",
    "block": "{\"symbols\":[\"@modalHeading\",\"@onCloseModal\",\"@modalContext\",\"@modalComponentName\",\"&attrs\",\"&default\"],\"statements\":[[7,\"tr\",false],[12,\"class\",[29,[\"table-row-component \",[23,0,[\"rowClassNames\"]]]]],[13,5],[8],[0,\"\\n  \"],[14,6],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isModalActive\"]]],null,{\"statements\":[[0,\"  \"],[5,\"table-row-modal\",[],[[\"@heading\",\"@onClose\"],[[23,1,[]],[23,2,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"component\",[[23,4,[]]],[[\"options\",\"onSave\"],[[23,3,[]],[23,2,[]]]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/table-row.hbs"
    }
  });

  _exports.default = _default;
});