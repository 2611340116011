define("trunk/components/fetchable-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    src: null,
    size: 64,
    isFetching: Ember.computed.empty('src'),
    style: Ember.computed('size', function () {
      return "width: ".concat(this.size, "px; height: ").concat(this.size, "px");
    }),
    imageStyle: Ember.computed('style', 'src', function () {
      return "".concat(this.style, "; background-image: url('").concat(this.src, "')");
    })
  });

  _exports.default = _default;
});