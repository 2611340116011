define("trunk/mixins/models/changeable", ["exports", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    newChangeset: function newChangeset() {
      return new _emberChangeset.default(this);
    },
    newChangesetWithValidations: function newChangesetWithValidations(validations) {
      return new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(validations), validations, // Disable automatic validations
      {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});