define("trunk/validations/product-variant", ["exports", "ember-changeset-validations/validators", "ember-changeset-conditional-validations/validators/sometimes"], function (_exports, _validators, _sometimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // Only validate name if there is more than one
    // variant on product
    name: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return this.get('product.hasVariants');
    }),
    sku: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});