define("trunk/components/select-input", ["exports", "ember-spread"], function (_exports, _emberSpread) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberSpread.default, {
    classNameBindings: ['errorClassName'],
    'data-test-select-input-component': Ember.computed.readOnly('key'),
    valueKey: 'value',
    errorClassName: Ember.computed('styleNamespace', 'hasErrors', function () {
      return this.hasErrors ? "".concat(this.styleNamespace, "--has-errors") : '';
    }),
    init: function init() {
      this._super.apply(this, arguments); // Dynamically define a computed property during runtime since 'key' here is dynamic


      Ember.defineProperty(this, 'selectedOption', Ember.computed("changeset.".concat(this.key), 'key', 'option', function () {
        var _this = this;

        return this.opts.find(function (o) {
          return o[_this.valueKey] == _this.changeset.get(_this.key);
        });
      }));
    },
    hasErrors: Ember.computed('changeset.error', function () {
      if (this.get('changeset.error.' + this.key)) {
        return true;
      }

      return false;
    }),
    actions: {
      selectOption: function selectOption(option) {
        this.changeset.set(this.key, option[this.valueKey]);

        if (this.onSelectOption) {
          this.onSelectOption(option);
        }
      }
    }
  });

  _exports.default = _default;
});