define("trunk/templates/components/pricing-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5onEY60m",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"external-link\",[[12,\"class\",[23,0,[\"classk\"]]]],[[\"@href\",\"@event\"],[[23,0,[\"href\"]],[23,0,[\"event\"]]]],{\"statements\":[[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/pricing-link.hbs"
    }
  });

  _exports.default = _default;
});