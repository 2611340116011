define("trunk/templates/components/password/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AFMGOC8b",
    "block": "{\"symbols\":[],\"statements\":[[5,\"simple-form\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading\"],[8],[0,\"Reset your password\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Email\"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[1,[23,0,[\"password\",\"email\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"New Password\"],[9],[0,\"\\n      \"],[5,\"text-input\",[],[[\"@type\",\"@changeset\",\"@key\",\"@class\",\"@isAutofocused\"],[\"password\",[23,0,[\"passwordChangeset\"]],\"value\",\"w-full\",true]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"simple-button\",[],[[\"@type\",\"@size\",\"@task\"],[\"submit\",\"submit\",[23,0,[\"savePasswordTask\"]]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/password/form.hbs"
    }
  });

  _exports.default = _default;
});