define("trunk/templates/components/input-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IxEAFaIA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-soft\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fas fa-info-circle\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ml-2\"],[8],[14,1],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/input-help.hbs"
    }
  });

  _exports.default = _default;
});