define("trunk/templates/components/components-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IbTlwUB1",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"i\",false],[12,\"class\",\"fa fa-cubes\"],[13,1],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/components-indicator.hbs"
    }
  });

  _exports.default = _default;
});