define("trunk/models/product", ["exports", "ember-data", "@ember-data/model", "trunk/mixins/models/changeable", "trunk/mixins/models/sales-channel-aggregatable", "trunk/mixins/models/variant-manyable", "ember-api-actions"], function (_exports, _emberData, _model, _changeable, _salesChannelAggregatable, _variantManyable, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, _salesChannelAggregatable.default, _variantManyable.default, {
    variants: _emberData.default.hasMany('product-variant', {
      async: false
    }),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    variantsCount: _emberData.default.attr('number'),
    sku: _emberData.default.attr('string'),
    skuPatternPrefixCount: _emberData.default.attr('number'),
    skuPatternSuffixCount: _emberData.default.attr('number'),
    availableQuantity: _emberData.default.attr('number'),
    onHandQuantity: _emberData.default.attr('number'),
    isAvailableQuantityThresholdAlerted: _emberData.default.attr('boolean'),
    // Used to contain custom data for requests
    meta: _emberData.default.attr(),
    // We have to use this type of path (/products/:id/variant) since ember-api-actions doesn't yet support nested ids
    // (/products/:id/variant/:variant_id)
    updateVariant: (0, _emberApiActions.memberAction)({
      path: 'variant',
      type: 'patch'
    }),
    hasVariants: Ember.computed('variantsCount', function () {
      // There is always at least one variant
      return this.variantsCount > 1;
    }),
    // If it has variants, it technically has components otherwise if it's simple, it depends on the variant
    hasComponents: Ember.computed('variants.@each.hasComponents', function () {
      if (this.isSimple) {
        return this.get('variant.hasComponents');
      } else {
        var variantsWithComponentsCount = this.variants.map(function (v) {
          return v.get('hasComponents');
        }).filter(function (b) {
          return b;
        }).length;
        return variantsWithComponentsCount > 0;
      }
    }),
    isSimple: Ember.computed('variantsCount', function () {
      return this.variantsCount == 1;
    }),
    variant: Ember.computed('variantsCount', function () {
      if (this.variantsCount > 1) {
        return null;
      } else {
        return this.get('variants.firstObject');
      }
    })
  });

  _exports.default = _default;
});