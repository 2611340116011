define("trunk/models/plan", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    coupon: _emberData.default.belongsTo('coupon', {
      async: false
    }),
    type: _emberData.default.attr('string'),
    price: _emberData.default.attr('string'),
    total: _emberData.default.attr('string'),
    externalServiceIdentifier: _emberData.default.attr('string'),
    externalServiceData: _emberData.default.attr(),
    interval: 'month',
    isDiscounted: Ember.computed('price', 'total', function () {
      return this.price != this.total;
    }),
    name: Ember.computed('type', function () {
      return this.type.capitalize();
    })
  });

  _exports.default = _default;
});