define("trunk/components/subscription-plan-modal/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-subscription-plan-button': Ember.computed.readOnly('plan.id'),
    classNames: ['subscription-plan-modal-button-component']
  });

  _exports.default = _default;
});