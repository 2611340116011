define("trunk/models/component", ["exports", "ember-data", "trunk/mixins/models/changeable"], function (_exports, _emberData, _changeable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, {
    parent: _emberData.default.belongsTo('productVariant', {
      async: false,
      inverse: 'components'
    }),
    productVariant: _emberData.default.belongsTo('productVariant', {
      async: false,
      inverse: 'componentizations'
    }),
    quantity: _emberData.default.attr('number')
  });

  _exports.default = _default;
});