define("trunk/models/external-service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    channelResources: _emberData.default.hasMany('external-service-channel-resource', {
      async: false
    })
  });

  _exports.default = _default;
});