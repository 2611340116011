define("trunk/models/listing-variant", ["exports", "ember-data", "ember-api-actions", "trunk/mixins/models/monetizable", "trunk/mixins/models/variantable"], function (_exports, _emberData, _emberApiActions, _monetizable, _variantable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_variantable.default, _monetizable.default, {
    listing: _emberData.default.belongsTo('listing', {
      async: false
    }),
    image: _emberData.default.belongsTo('listing-image', {
      async: false
    }),
    images: _emberData.default.hasMany('listing-image', {
      async: false
    }),
    title: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    sku: _emberData.default.attr('string'),
    properties: _emberData.default.attr(),
    availableQuantity: _emberData.default.attr('number'),
    onHandQuantity: _emberData.default.attr('number'),
    isLinked: _emberData.default.attr('boolean'),
    externalServiceType: _emberData.default.attr('string'),
    externalServiceIdentifier: _emberData.default.attr('string'),
    externalServiceData: _emberData.default.attr(),
    triggerAutolink: (0, _emberApiActions.memberAction)({
      path: 'autolink',
      type: 'post'
    })
  });

  _exports.default = _default;
});