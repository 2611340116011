define("trunk/templates/external-service-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZA6R5wbI",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[0,\"List of External service accounts\\n\\n\"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\",\"externalServiceAccounts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"external-service-accounts.show\",[23,1,[\"id\"]]]],{\"statements\":[[1,[23,1,[\"externalServiceType\"]],false]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/external-service-accounts/index.hbs"
    }
  });

  _exports.default = _default;
});