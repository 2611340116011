define("trunk/models/external-service-account", ["exports", "ember-data", "ember-api-actions", "trunk/mixins/models/changeable", "trunk/mixins/models/external-serviceable"], function (_exports, _emberData, _emberApiActions, _changeable, _externalServiceable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, _externalServiceable.default, {
    salesChannels: _emberData.default.hasMany('sales-channel', {
      async: false
    }),
    site: _emberData.default.attr('string'),
    accessToken: _emberData.default.attr('string'),
    accessSecret: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    stateReasonKind: _emberData.default.attr('string'),
    externalServiceIdentifier: _emberData.default.attr('string'),
    externalServiceData: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    initiateAuthorization: (0, _emberApiActions.memberAction)({
      path: 'initiate-authorization',
      type: 'patch'
    }),
    completeAuthorization: (0, _emberApiActions.memberAction)({
      path: 'complete-authorization',
      type: 'patch',
      urlType: 'completeAuthorizationQuery'
    }),
    initiateReauthorization: (0, _emberApiActions.memberAction)({
      path: 'initiate-reauthorization',
      type: 'patch'
    }),
    revokeAuthorization: (0, _emberApiActions.memberAction)({
      path: 'revoke-authorization',
      type: 'patch'
    })
  });

  _exports.default = _default;
});