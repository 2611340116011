define("trunk/templates/settings/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kN4Mapnu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"page-heading\"],[8],[0,\"Account\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[5,\"text-input\",[],[[\"@type\",\"@changeset\",\"@key\",\"@class\"],[\"text\",[23,0,[\"userChangeset\"]],\"name\",\"w-80\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Email\"],[9],[0,\"\\n      \"],[5,\"text-input\",[],[[\"@type\",\"@changeset\",\"@key\",\"@class\"],[\"email\",[23,0,[\"userChangeset\"]],\"email\",\"w-80\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"simple-button\",[],[[\"@type\",\"@size\",\"@changeset\",\"@task\"],[\"submit\",\"submit\",[23,0,[\"userChangeset\"]],[23,0,[\"saveTask\"]]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group__label\"],[8],[0,\"Password\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"block\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://go.trunkinventory.com/help-change-password\"],[10,\"class\",\"secondary-link\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"Change\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/settings/account.hbs"
    }
  });

  _exports.default = _default;
});