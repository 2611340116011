define("trunk/templates/components/sales-channel-quantity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y6FAC9tL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"isSoldOut\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"text-warning\"],[8],[7,\"i\",true],[10,\"class\",\"far fa-times\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[28,\"format-number\",[[23,0,[\"quantity\"]]],null],false],[4,\"if\",[[23,0,[\"isVariableQuantity\"]]],null,{\"statements\":[[0,\"+\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,0,[\"isLinkedCountVisible\"]]],null,{\"statements\":[[0,\" (\"],[1,[23,0,[\"linkedCount\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/sales-channel-quantity.hbs"
    }
  });

  _exports.default = _default;
});