define("trunk/templates/components/external-service-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LsgnardE",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"inline-flex items-center\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"flex items-center \",[23,0,[\"styleNamespace\"]],\"__icon \",[28,\"if\",[[23,0,[\"isCompact\"]],[28,\"concat\",[[23,0,[\"styleNamespace\"]],\"__icon--compact\"],null]],null],\" \",[23,0,[\"iconClassName\"]]]]],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[[23,0,[\"iconFilename\"]]],[[\"class\"],[\"relative\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[14,2],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/external-service-icon.hbs"
    }
  });

  _exports.default = _default;
});