define("trunk/templates/components/subscription-plan-modal/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "brEDuB66",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"subscription-plan-modal-button-component__content\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[1,[23,0,[\"plan\",\"name\"]],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[14,1],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"subscription-plan-modal-button-component__price\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"plan\",\"isDiscounted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"subscription-plan-modal-button-component__price-original\"],[8],[1,[28,\"format-money\",[[23,0,[\"plan\",\"price\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[10,\"class\",\"subscription-plan-modal-button-component__price-amount\"],[8],[1,[28,\"format-money\",[[23,0,[\"plan\",\"total\"]]],null],false],[9],[7,\"span\",true],[10,\"class\",\"subscription-plan-modal-button-component__price-per\"],[8],[0,\"/\"],[9],[7,\"span\",true],[10,\"class\",\"subscription-plan-modal-button-component__price-interval\"],[8],[1,[23,0,[\"plan\",\"interval\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/subscription-plan-modal/button.hbs"
    }
  });

  _exports.default = _default;
});