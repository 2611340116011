define("trunk/templates/components/intercom-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hg4EVFax",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"span\",false],[13,1],[3,\"on\",[\"click\",[23,0,[\"trigger\"]]]],[8],[14,2],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/intercom-trigger.hbs"
    }
  });

  _exports.default = _default;
});