define("trunk/templates/components/text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03HFvbvc",
    "block": "{\"symbols\":[\"@icon\",\"@class\",\"&attrs\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"styleNamespace\"]],\" inline-block relative \",[23,2,[]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasIcon\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"absolute-y-center left-4\"],[8],[0,\"\\n        \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"absolute-y-center text-muted left-4\"],[8],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[29,[\"fas fa-\",[23,1,[]]]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[7,\"input\",false],[12,\"value\",[29,[[23,0,[\"value\"]]]]],[12,\"class\",[29,[[23,0,[\"inputClassNames\"]]]]],[13,3],[12,\"type\",[29,[[23,0,[\"type\"]]]]],[3,\"create-ref\",[\"input\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[23,0,[]]]]],[3,\"on\",[\"input\",[23,0,[\"handleChange\"]]]],[3,\"on\",[\"focusin\",[23,0,[\"handleFocusIn\"]]]],[3,\"on\",[\"focusout\",[23,0,[\"handleFocusOut\"]]]],[3,\"did-insert\",[[23,0,[\"attemptToFocus\"]],[23,0,[\"isAutofocused\"]]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"areErrorsVisible\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"styleNamespace\"]],\"__error absolute bg-danger text-sm text-white h-6 px-3 rounded align-middle\"]]],[10,\"style\",\"top: 30px; right: 7px\"],[8],[0,\"\\n      \"],[1,[28,\"capitalize\",[[23,0,[\"error\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/text-input.hbs"
    }
  });

  _exports.default = _default;
});