define("trunk/validations/inventory", ["exports", "ember-changeset-validations/validators", "ember-changeset-conditional-validations/validators/sometimes"], function (_exports, _validators, _sometimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    availableQuantity: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      integer: true
    })],
    availableQuantityAlertThreshold: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      integer: true
    })], function (changes, content) {
      return this.get('isAvailableQuantityAlertThresholdRequired') == true;
    })
  };
  _exports.default = _default;
});