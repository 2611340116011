define("trunk/templates/components/settings-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iTC/ywgP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"styleNamespace\"]],\"__nav mr-5\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"styleNamespace\"]],\"__nav-section\"]]],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"heading\"],[8],[0,\"You\"],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"data-intercom-target\",\"Settings nav account\"]],[[\"@route\"],[\"settings.account\"]],{\"statements\":[[0,\"\\n      Account\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"styleNamespace\"]],\"__nav-section\"]]],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"heading\"],[8],[1,[23,0,[\"currentUser\",\"organization\",\"name\"]],false],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"settings.organization\"]],{\"statements\":[[0,\"\\n      Organization\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"data-intercom-target\",\"Settings nav billing\"]],[[\"@route\"],[\"settings.billing\"]],{\"statements\":[[0,\"\\n      Billing\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"styleNamespace\"]],\"__content content-block\"]]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/settings-layout.hbs"
    }
  });

  _exports.default = _default;
});