define("trunk/mixins/controllers/paginatable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['page', {
      perPage: 'per'
    }],
    page: 1,
    perPage: 20,
    resetPage: function resetPage() {
      this.set('page', 1);
    }
  });

  _exports.default = _default;
});