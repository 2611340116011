define("trunk/templates/components/variant-manyable-sku", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QHmvuDrC",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[4,\"if\",[[23,0,[\"prefixPattern\"]]],null,{\"statements\":[[7,\"span\",true],[11,\"class\",[23,0,[\"patternClass\"]]],[8],[1,[23,0,[\"prefixPattern\"]],false],[9]],\"parameters\":[]},null],[1,[23,1,[\"skuPretty\"]],false],[4,\"if\",[[23,0,[\"suffixPattern\"]]],null,{\"statements\":[[7,\"span\",true],[11,\"class\",[23,0,[\"patternClass\"]]],[8],[1,[23,0,[\"suffixPattern\"]],false],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/variant-manyable-sku.hbs"
    }
  });

  _exports.default = _default;
});