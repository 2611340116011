define("trunk/templates/components/simple-dropdown/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OFRzK9P/",
    "block": "{\"symbols\":[\"@dropdown\",\"&default\",\"&attrs\",\"@isLoading\"],\"statements\":[[6,[23,1,[\"trigger\"]],[[12,\"class\",\"inline-block\"],[13,3]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/simple-dropdown/trigger.hbs"
    }
  });

  _exports.default = _default;
});