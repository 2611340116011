define("trunk/templates/components/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hE4qaVP8",
    "block": "{\"symbols\":[\"result\",\"&default\",\"&attrs\",\"@query\",\"@searchTask\",\"@placeholder\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"search-input-component \",[28,\"if\",[[23,0,[\"isActive\"]],\"search-input-component--active\"],null]]]],[13,3],[3,\"create-ref\",[\"component\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[23,0,[]]]]],[8],[0,\"\\n  \"],[5,\"text-input\",[[12,\"autocomplete\",\"off\"],[12,\"placeholder\",[23,6,[]]]],[[\"@value\",\"@class\",\"@icon\",\"@isLoading\",\"@onChange\",\"@onFocusIn\",\"@onFocusOut\"],[[23,4,[]],\"w-full\",\"search\",[23,5,[\"isRunning\"]],[23,0,[\"handleInputChange\"]],[23,0,[\"handleInputFocusIn\"]],[23,0,[\"handleInputFocusOut\"]]]]],[0,\"\\n\"],[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"overflow-y-scroll overflow-x-hidden search-input-component__results \",[28,\"if\",[[23,0,[\"isActive\"]],\"visible\",\"invisible\"],null]]]],[12,\"style\",[23,0,[\"resultsStyle\"]]],[12,\"role\",\"presentation\"],[3,\"on\",[\"mousedown\",[23,0,[\"handleResultsMouseDown\"]]]],[3,\"on\",[\"mouseup\",[23,0,[\"handleResultsMouseUp\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"results\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[23,0,[\"results\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"h-14 px-4 border-light border-b last:border-none\"],[8],[0,\"\\n          \"],[14,2,[[28,\"hash\",null,[[\"result\",\"trigger\"],[[23,1,[]],[28,\"component\",[\"search-input/trigger\"],[[\"onClick\"],[[28,\"fn\",[[23,0,[\"handleTriggerClick\"]],[23,1,[]]],null]]]]]]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"empty-placeholder\",[[12,\"class\",\"p-6\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[23,0,[\"emptyResultsPlaceholder\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/search-input.hbs"
    }
  });

  _exports.default = _default;
});