define("trunk/mixins/routes/records-findable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    findRecordsTaskName: '',
    actions: {
      willTransition: function willTransition(transition) {
        // Clear out task if transitioning to different route
        if (transition.targetName != this.routeName) {
          this.set(this.findRecordsTaskName + '.lastSuccessful', null);
        }
      }
    }
  });

  _exports.default = _default;
});