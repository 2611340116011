define("trunk/templates/components/loaded-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vRFNSYzM",
    "block": "{\"symbols\":[\"&attrs\",\"@onLoaded\",\"&default\",\"@task\"],\"statements\":[[4,\"if\",[[23,4,[\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[13,1],[3,\"did-insert\",[[23,0,[\"handleLoaded\"]],[23,2,[]]]],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/loaded-content.hbs"
    }
  });

  _exports.default = _default;
});