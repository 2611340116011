define("trunk/templates/components/loaded-records/any", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KkxSuOrA",
    "block": "{\"symbols\":[\"@pagesCount\",\"@records\",\"&default\",\"@isVisible\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[14,3,[[28,\"hash\",null,[[\"records\",\"pagesCount\"],[[23,2,[]],[23,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/loaded-records/any.hbs"
    }
  });

  _exports.default = _default;
});