define("trunk/templates/components/loading-spinner-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JgZnPUhr",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[5,\"ember-wormhole\",[[12,\"class\",\"hidden\"]],[[\"@to\"],[\"loading-spinner-modal\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[23,0,[\"styleNamespace\"]]],[13,1],[8],[0,\"\\n    \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/loading-spinner-modal.hbs"
    }
  });

  _exports.default = _default;
});