define("trunk/models/sales-channel", ["exports", "ember-data", "trunk/mixins/models/external-serviceable"], function (_exports, _emberData, _externalServiceable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_externalServiceable.default, {
    externalServiceAccount: _emberData.default.belongsTo('external-service-account', {
      async: false
    }),
    name: _emberData.default.attr('string'),
    externalServiceIdentifier: _emberData.default.attr('string'),
    externalServiceTypeSupersededBy: _emberData.default.attr('string'),
    listingsCount: _emberData.default.attr('number'),
    isInventoryAdjustmentsPushed: _emberData.default.attr('boolean'),
    isInventoryAdjustmentsPulled: _emberData.default.attr('boolean'),
    isListingsAutolinked: _emberData.default.attr('boolean'),
    isListingsAutolinkedMultiple: _emberData.default.attr('boolean'),
    isListingsAutoinventoried: _emberData.default.attr('boolean'),
    isReauthorizable: _emberData.default.attr('boolean'),
    isRevokable: _emberData.default.attr('boolean'),
    state: _emberData.default.attr('string'),
    stateReasonKind: _emberData.default.attr('string'),
    refreshedAt: _emberData.default.attr('date'),
    // Used to contain custom data for requests
    meta: _emberData.default.attr(),
    label: Ember.computed('name', function () {
      if (Ember.isEmpty(this.name)) {
        return this.get('externalServiceData.name');
      } else {
        return this.name;
      }
    }),
    externalServiceChannelResource: Ember.computed('externalServiceAccount', 'externalServiceIdentifier', 'externalServiceData', {
      set: function set(key, value) {
        if (value) {
          this.set('externalServiceAccount', value.get('externalServiceAccount'));
          this.set('externalServiceIdentifier', value.get('id'));
          this.set('externalServiceData', Object.assign({}, this.externalServiceData, {
            name: value.get('name')
          }));
        }

        return true;
      }
    }),
    stateBadgeType: Ember.computed('state', function () {
      var states = {
        active: 'success'
      };
      return states[this.state];
    })
  });

  _exports.default = _default;
});