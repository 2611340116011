define("trunk/templates/components/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fwXMtLh4",
    "block": "{\"symbols\":[\"&attrs\",\"@query\",\"@placeholder\"],\"statements\":[[7,\"form\",false],[13,1],[3,\"on\",[\"submit\",[23,0,[\"handleSubmit\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group form-group--inline\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"relative\"],[8],[0,\"\\n      \"],[5,\"text-input\",[[12,\"placeholder\",[23,3,[]]]],[[\"@class\",\"@value\",\"@icon\",\"@onChange\"],[\"w-96\",[23,2,[]],\"search\",[23,0,[\"handleQueryChange\"]]]]],[0,\"\\n\"],[4,\"unless\",[[23,0,[\"isQueryBlank\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",\"absolute-y-center inline-block cursor-pointer text-primary hover:text-primary-hover text-sm right-4\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"resetQuery\"]]]],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fas fa-times\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/search-bar.hbs"
    }
  });

  _exports.default = _default;
});