define("trunk/helpers/format-cents", ["exports", "accounting"], function (_exports, _accounting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCents = formatCents;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatCents(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        cents = _ref2[0],
        currency = _ref2[1];

    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!cents) return;
    var currencies = {
      'USD': '$',
      // US Dollar
      'EUR': '€',
      // Euro
      'CRC': '₡',
      // Costa Rican Colón
      'GBP': '£',
      // British Pound Sterling
      'ILS': '₪',
      // Israeli New Sheqel
      'INR': '₹',
      // Indian Rupee
      'JPY': '¥',
      // Japanese Yen
      'KRW': '₩',
      // South Korean Won
      'NGN': '₦',
      // Nigerian Naira
      'PHP': '₱',
      // Philippine Peso
      'PLN': 'zł',
      // Polish Zloty
      'PYG': '₲',
      // Paraguayan Guarani
      'THB': '฿',
      // Thai Baht
      'UAH': '₴',
      // Ukrainian Hryvnia
      'VND': '₫' // Vietnamese Dong

    };
    var accountingOptions = {
      symbol: currencies[currency],
      precision: 2
    }; // If simple style don't show decimals if there are none

    if (options.style == 'simple') {
      if (cents % 100 == 0) {
        accountingOptions.precision = 0;
      }
    }

    return _accounting.default.formatMoney(cents / 100, accountingOptions);
  }

  var _default = Ember.Helper.helper(formatCents);

  _exports.default = _default;
});