define("trunk/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mZcJEGze",
    "block": "{\"symbols\":[\"component\"],\"statements\":[[7,\"script\",true],[10,\"src\",\"https://checkout.stripe.com/checkout.js\"],[8],[9],[0,\"\\n\"],[7,\"div\",false],[3,\"did-insert\",[[23,0,[\"removeInitialLoadingSpinner\"]]]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flash-messages\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"latestFlashMessage\"]]],null,{\"statements\":[[0,\"    \"],[5,\"flash-message\",[],[[\"@flash\"],[[23,0,[\"latestFlashMessage\"]]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[8],[1,[28,\"render-markdown\",[[23,0,[\"latestFlashMessageBody\"]]],null],false],[9],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/application.hbs"
    }
  });

  _exports.default = _default;
});