define("trunk/mixins/models/variant-manyable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    skuPretty: Ember.computed('sku', function () {
      if (Ember.isBlank(this.sku)) {
        return '';
      } else {
        return "".concat(this.sku);
      }
    }),
    skuPattern: Ember.computed('sku', 'prefixPattern', 'suffixPattern', function () {
      return "".concat(this.prefixPattern).concat(this.skuPretty).concat(this.suffixPattern);
    }),
    prefixPattern: Ember.computed('skuPatternPrefixCount', function () {
      return this.buildPattern(this.skuPatternPrefixCount);
    }),
    suffixPattern: Ember.computed('skuPatternSuffixCount', function () {
      return this.buildPattern(this.skuPatternSuffixCount);
    }),
    buildPattern: function buildPattern(count) {
      if (count == 0) return '';
      var pattern = '';

      for (var i = 0; i < count; i++) {
        pattern += '#';
      }

      return pattern;
    }
  });

  _exports.default = _default;
});