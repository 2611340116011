define("trunk/validations/product", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)(true),
    availableQuantity: (0, _validators.validatePresence)(true),
    sku: [function (key, newValue, oldValue, changes, record) {
      // Only check for presence of SKU if new record
      if (record.get('isNew')) {
        return (0, _validators.validatePresence)(true).apply(void 0, arguments);
      }

      return true;
    }]
  };
  _exports.default = _default;
});