define("trunk/templates/components/linked-sales-channel-quantities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8MAiFqIB",
    "block": "{\"symbols\":[\"aggregation\"],\"statements\":[[4,\"each\",[[23,0,[\"aggregationsBySalesChannelName\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[11,\"title\",[23,1,[\"salesChannel\",\"name\"]]],[8],[0,\"\\n    \"],[5,\"external-service-icon\",[[12,\"class\",\"mr-2\"]],[[\"@type\",\"@title\",\"@isCompact\"],[[23,1,[\"salesChannel\",\"externalServiceType\"]],[23,1,[\"salesChannel\",\"name\"]],true]],{\"statements\":[[0,\"\\n      \"],[5,\"sales-channel-quantity\",[],[[\"@aggregatable\",\"@salesChannel\",\"@quantity\",\"@isVariableQuantity\",\"@linkedCount\",\"@isSoldOut\"],[[23,0,[\"aggregatable\"]],[23,1,[\"salesChannel\"]],[23,1,[\"availableQuantity\"]],[23,1,[\"isVariableAvailableQuantity\"]],[23,1,[\"linkedCount\"]],[23,1,[\"isSoldOut\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trunk/templates/components/linked-sales-channel-quantities.hbs"
    }
  });

  _exports.default = _default;
});