define("trunk/mixins/routes/unauthenticated", ["exports", "trunk/config/environment", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _environment, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: _environment.default.APP.routeIfAlreadyAuthenticated
  });

  _exports.default = _default;
});